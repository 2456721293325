import { render, staticRenderFns } from "./EmailVerified.vue?vue&type=template&id=853f0060&scoped=true"
import script from "./EmailVerified.vue?vue&type=script&lang=js"
export * from "./EmailVerified.vue?vue&type=script&lang=js"
import style0 from "./EmailVerified.vue?vue&type=style&index=0&id=853f0060&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "853f0060",
  null
  
)

export default component.exports